import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { selectAccount, selectedAccount } from "src/store/slices/accountSlice";
import { setting } from "src/store/slices/appSlice";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import { useLocation } from "react-router-dom";
import Notifications from "src/components/notifications";
import UserNotificationApi from "src/api/user-notification-api";

export default function Header() {
  const [show, setShow] = useState(false);
  const currentUser = useSelector(selectedAccount);
  const currentSetting = useSelector(setting);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [keyword, setKeyword] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [notifications, setNotifications] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doSearch = () => {
    if (keyword && keyword != "") {
      history.push(`/search/${encodeURIComponent(keyword)}`);
    }
  };

  useEffect(() => {
    const userProfile = window.localStorage.getItem("userData");
    if (userProfile) {
      dispatch(selectAccount(JSON.parse(userProfile)));
    }
  }, []);

  useEffect(() => {
    setShow(false);
    setKeyword("");
  }, [location]);

  const handleLogout = () => {
    // new UserApi().logout().then((response) => {
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem("accessToken");
    dispatch(selectAccount(null));
    history.push("/");
    // });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      doSearch();
    }
  };

  const fetchData = () => {
    if(!currentUser) return;
    var api = new UserNotificationApi();
    api.scan().then((response) => {});
    api.searches({ Status: 0, PageSize: 6 }).then((response) => {
      setNotifications(response.data.data);
    });
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 15000 * 1);
    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <div className="header" id="head-mobi">
        <div className="container">
          <nav className="navbar navbar-expand-lg justify-content-between ">
            <ul className="list-logo">
              <li>
                <Link className="navbar-brand" to="/">
                  <img src="/theme/img/logo.svg" />
                </Link>
              </li>
              {currentSetting && !currentSetting.isOpenFilter && (
                <li className="" id="#search-header-mobile">
                  <div className="search-mobile" style={{ marginTop: 16 }}>
                    <button className="btn-search" onClick={doSearch}>
                      <img className="" src="/theme/img/icon-search.svg" />
                    </button>
                    <input
                      type="text"
                      onKeyDown={handleKeyDown}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      className="input-search"
                      placeholder="Nhập tài liệu, ít nhất 3 ký tự...."
                    />
                  </div>
                </li>
              )}
            </ul>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setShow(!show)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={
                show
                  ? "collapse navbar-collapse show"
                  : "navbar-collapse collapse"
              }
              style={{ flexGrow: 0 }}
            >
              <ul className="navbar-nav mb-2 mb-lg-0 ">
                <li className="nav-item">
                  <a className="nav-link active" href="#">
                    Giới thiệu
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/contents" className="nav-link">
                    Danh mục Tra cứu
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Liên hệ
                  </a>
                </li>
                {currentUser && currentUser.id && (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/userprofile"
                        className="nav-link"
                        style={{ color: "rgba(0, 0, 0, 0.87)" }}
                      >
                        Tài khoản của tôi
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" onClick={handleLogout}>
                        Thoát
                      </a>
                    </li>
                  </>
                )}
                {(!currentUser || !currentUser.id) && (
                  <li className="nav-item">
                    {/* <button
                    className="btn btn-sm btn-outline-white"
                    type="submit"
                  >
                    Đăng ký
                  </button> */}
                    {/* <button className="btn btn-sm btn-white" type="submit">
                      Đăng Nhập
                    </button> */}
                    <Link className="btn btn-sm btn-white" to="/login">
                      Đăng Nhập
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            {currentUser && currentUser.id && (
              <div className="dropdown dropdown-edit">
                <Notifications notifications={notifications} />
                <a
                  onClick={handleMenu}
                  className="btn-icon-login dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <img src="/theme/img/icon-user.svg" />
                </a>
                <Menu
                  id="menu-appbar"
                  className="btn-icon-login dropdown-toggle"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Link
                    to="/userprofile"
                    style={{ color: "rgba(0, 0, 0, 0.87)" }}
                  >
                    <MenuItem onClick={handleClose}>Tài khoản của tôi</MenuItem>
                  </Link>
                  <Divider />
                  <MenuItem onClick={handleLogout}>Thoát</MenuItem>
                </Menu>
              </div>
            )}
          </nav>
        </div>
      </div>

      <div className="header" id="head-desk">
        <div className="container">
          <nav className="navbar navbar-expand-lg justify-content-between ">
            <ul className="list-logo">
              <li>
                <Link className="navbar-brand" to="/">
                  <img src="/theme/img/logo.svg" />
                </Link>
              </li>
            </ul>
            <button className="navbar-toggler" type="button">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarTogglerDemo03"
              style={{ flexGrow: 0 }}
            >
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" href="#">
                    Giới thiệu
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/contents" className="nav-link">
                    Danh mục Tra cứu
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Liên hệ
                  </a>
                </li>
                {currentSetting && !currentSetting.isOpenFilter && (
                  <li className="nav-item " id="search-header">
                    <div className="search-fix" style={{ display: "block" }}>
                      <div className="search-box">
                        <button className="btn-search" onClick={doSearch}>
                          <img className="" src="/theme/img/icon-search.svg" />
                        </button>
                        <input
                          value={keyword}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => setKeyword(e.target.value)}
                          type="text"
                          className="input-search"
                          placeholder="Nhập tài liệu, ít nhất 3 ký tự...."
                        />
                      </div>
                    </div>
                  </li>
                )}

                {currentUser && currentUser.id && (
                  <>
                    <li className="nav-item dropdown dropdown-edit">
                      <Notifications notifications={notifications} />
                      <a
                        onClick={handleMenu}
                        className="btn-icon-login dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <img src="/theme/img/icon-user.svg" />
                      </a>
                      <Menu
                        id="menu-appbar"
                        className="btn-icon-login dropdown-toggle"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <Link
                          to="/userprofile"
                          style={{ color: "rgba(0, 0, 0, 0.87)" }}
                        >
                          <MenuItem onClick={handleClose}>
                            Tài khoản của tôi
                          </MenuItem>
                        </Link>
                        <Divider />
                        <MenuItem onClick={handleLogout}>Thoát</MenuItem>
                      </Menu>
                    </li>
                  </>
                )}

                {(!currentUser || !currentUser.id) && (
                  <li className="nav-item">
                    <Link className="btn btn-sm btn-white" to="/login">
                      Đăng Nhập
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
}
