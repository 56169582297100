import ApiBase from './api-base';
import axios from 'axios';

export default class DocumentDetailApi extends ApiBase {
  constructor() {
    super('documentdetail');
  }

  getAll = () => {
    const url = `${this.baseApiUrl}/getAll`;
    const response = axios.get(url);
    return response;
  };
}
