import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Button, Grid } from "@mui/material";
import ExpertList from "./list";

function Expert() {
  return (
    <>
      {/* Chuyên gia tư vấn */}
      <div className="main-advise">
        <div className="container">
          <div className="main-title">
            <h2>Chuyên gia tư vấn</h2>
          </div>
          <OwlCarousel
            className="owl-carousel owl-theme advise-carousel"
            loop
            items={4}
            margin={10}
            nav
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 3,
              },
            }}
          >
            <ExpertList />
          </OwlCarousel>
        </div>
      </div>
    </>
  );
}

export default Expert;
