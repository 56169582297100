import "./App.css";

import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Interceptor from "src/services/interceptor";
import { store } from "src/store";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Footer from "./Footer";
import Header from "./Header";
import ContentDetail from "./pages/content/detail";
import ContentList from "./pages/content/list";
import DocumentContentDetail from "./pages/document-content/detail";
import DocumentDetailDetail from "./pages/document-detail/detail";
import DocumentContentList from "./pages/document-content/list";
import DocumentDetail from "./pages/document/detail";
import NotificationList from "./pages/notifications/list";
import Home from "./pages/home";
import Login from "./pages/login";
import NewsDetail from "./pages/news/detail";
import NewsList from "./pages/news/list";
import UserProfile from "./pages/profile";
import UserSetting from "./pages/profile/setting";
import Search from "./pages/search";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function App() {
  new Interceptor().initialize();

  const [modules, setModules] = useCookies(["modules"]);

  useEffect(() => {
    const currentConfig = modules.modules ?? [
      "feature-news",
      "feature-document-contents",
      "interest-document-contents",
      "consultants",
    ];
    if (!modules.modules) {
      setModules("modules", JSON.stringify(currentConfig));
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <CssBaseline />
          <Router>
            <Header title="Blog" />
            <div className="main">
              <Switch>
                <Route path="/login/:token?">
                  <Login />
                </Route>
                <Route path="/userprofile">
                  <UserProfile />
                </Route>
                <Route path="/usersetting">
                  <UserSetting />
                </Route>
                <Route path="/search/:keyword?">
                  <Search />
                </Route>
                <Route path="/news/:id">
                  <NewsDetail />
                </Route>
                <Route path="/news">
                  <NewsList />
                </Route>
                <Route path="/document/:id">
                  <DocumentDetail />
                </Route>
                <Route path="/documentcontent/:id">
                  <DocumentContentDetail />
                </Route>
                <Route path="/documentdetail/:id">
                  <DocumentDetailDetail />
                </Route>
                <Route path="/documentcontent">
                  <DocumentContentList />
                </Route>
                <Route path="/content/:id">
                  <ContentDetail />
                </Route>
                <Route path="/contents/:fieldId?/:subjectId?">
                  <ContentList />
                </Route>
                <Route path="/notifications">
                  <NotificationList />
                </Route>
                <Route path="/app/documents/view/:id">
                  <DocumentDetail />
                </Route>
                <Route path="/app/documentcontents/view/:id">
                  <ContentDetail />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </div>
          </Router>
          <Footer
            title="Footer"
            description="Something here to give the footer a purpose!"
          />
          <button
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            id="myBtn"
            title="Go to top"
            style={{ display: "block", width: 44, height: 42, padding: 0 }}
          >
            <img src="/theme/img/icon-arowup.svg" />
          </button>
        </CookiesProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
