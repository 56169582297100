import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Button, Grid } from "@mui/material";

function ExpertList() {
  return (
    <>
      <a href="">
        <span>
          <img src="/theme/img/img1.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img2.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img3.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img4.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img1.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img2.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img3.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img4.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img1.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img2.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img3.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
      <a href="">
        <span>
          <img src="/theme/img/img4.png" />
        </span>
        <label>Chuyên gia A</label>
        <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
        <span className="icon-docs">
          <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
        </span>
      </a>
    </>
  );
}

export default ExpertList;
