import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { saveSetting } from "src/store/slices/moduleSlice";
import ExpertList from "src/components/expert/list";

function Consultants() {
  const [modules, setModules] = useCookies(["modules"]);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const moveTop = (module) => {
    let currentConfig = modules.modules;
    const index = currentConfig.indexOf(module);
    currentConfig.splice(index, 1);
    currentConfig[0] = module;
    setModules("modules", JSON.stringify(currentConfig));
    dispatch(saveSetting(currentConfig));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (modules.modules && modules.modules[0] == "consultants") {
      setIsActive(true);
    }
  }, [modules]);

  return (
    <>
      {/* Chuyên gia tư vấn */}
      <div className="main-advise" style={{ paddingTop: isActive ? 20 : 60 }}>
        <div className="container">
          {modules.modules && modules.modules[0] != "consultants" && (
            <a
              style={{ cursor: "pointer" }}
              className="d-block mb-3 upto"
              onClick={() => moveTop("consultants")}
            >
              <img src="/theme/img/icon-upto.svg" />
            </a>
          )}
          <div className="main-title text-center">
            <h2>Chuyên gia tư vấn</h2>
          </div>
          <OwlCarousel
            className="owl-carousel owl-theme advise-carousel"
            loop
            items={4}
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 4,
              },
            }}
            margin={10}
            nav
          >
           <ExpertList/>
          </OwlCarousel>
        </div>
      </div>
    </>
  );
}

export default Consultants;
